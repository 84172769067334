import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Container = () => (
  <Layout>
    <SEO title="Home" />
    <div className="home">
      <header
        className="color-bg light-typo padding-top-bottom bg-image detail-header"
        style={{
          backgroundImage: "url(/images/etc/reservation_header.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <h1 className="page-title">유의사항 / 환불기준</h1>

          <p className="page-subtitle">
            예약하기 전 미리 알아두셔야할 것들과 환불기준을 설명해 드릴게요.
          </p>
        </div>
      </header>

      <article className="post single-post">
        <div className="container fade-up">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1">
              <div className="post-content">
                <div>
                  <h2 className="text-center">유의 사항</h2>
                  <p>
                    <i className="fa fa-check-circle"></i> 예약금을{" "}
                    <b>입금하셔야 예약이 완료</b>됩니다.
                  </p>
                  <p>
                    <i className="fa fa-check-circle"></i> 입금은 꼭 예약자
                    이름으로 해주시고 <b>전화로 확인</b>해 주세요.
                  </p>
                  <p>
                    <i className="fa fa-check-circle"></i> 위생 상의 문제와 다른
                    고객이 불편해 할 수 있으므로 <b>애완동물</b>은 데려오실 수
                    없습니다. 양해부탁드립니다.
                  </p>
                  <p>
                    <i className="fa fa-check-circle"></i> 다른 손님들께
                    불편함이 없도록{" "}
                    <b>무분별한 오락, 음주 및 고상방가를 자제</b>해 주시길
                    바랍니다.
                  </p>
                  <p>
                    <i className="fa fa-check-circle"></i> 이용 시{" "}
                    <b>시설물 훼손, 분실에 대한 책임</b>은 투숙객에게 있으므로
                    유의하시기 바랍니다.
                  </p>
                  <p>
                    <i className="fa fa-check-circle"></i> 객실정리 후{" "}
                    <b>퇴실점검></b>을 받으셔야 합니다. 점검 후{" "}
                    <b>객실키를 반납</b>해 주세요.
                  </p>
                  <p>
                    <i className="fa fa-check-circle"></i> 쓰레기는 지정된
                    장소에 <b>분리수거</b>해 주세요.
                  </p>
                  <p>
                    <i className="fa fa-check-circle"></i>{" "}
                    <b>입실시간은 15:00부터</b>입니다.
                  </p>
                  <p>
                    <i className="fa fa-check-circle"></i>{" "}
                    <b>퇴실시간은 마지막 이용일 오전 11:00까지</b>입니다. (다음
                    손님을 위한 준비를 위해 퇴실시간을 준수해 주시기바랍니다.
                  </p>
                </div>

                <br />
                <br />

                <div>
                  <h2 className="text-center">환불 기준</h2>
                  <div>
                    <p>
                      <span style={{ fontSize: "14px", color: "#7a7c7f" }}>
                        * 취소수수료는 예약일자가 아닌 이용일 기준입니다.
                      </span>
                      <br />
                      <span style={{ fontSize: "14px", color: "#7a7c7f" }}>
                        * 전 기간 취소수수료는 공제금액이 큰 금액을 적용합니다.
                      </span>
                    </p>
                    <table className="table table-brodered">
                      <tbody>
                        <tr className="active">
                          <td>취소 날짜</td>
                          <td>환불 금액</td>
                        </tr>
                        <tr className="">
                          <td>이용당일 </td>
                          <td>환불 불가</td>
                        </tr>
                        <tr className="">
                          <td>1일 전 </td>
                          <td>30% 환불</td>
                        </tr>
                        <tr className="">
                          <td>2일 전 </td>
                          <td>50% 환불</td>
                        </tr>
                        <tr className="">
                          <td>3일 전 </td>
                          <td>70% 환불</td>
                        </tr>
                        <tr className="">
                          <td>4일 전 </td>
                          <td>80% 환불</td>
                        </tr>
                        <tr className="">
                          <td>5일 전 </td>
                          <td>90% 환불</td>
                        </tr>
                        <tr className="">
                          <td>6일 이상 전</td>
                          <td>95% 환불 (송금수수료 포함)</td>
                        </tr>
                        <tr className="">
                          <td>6일 이상 전 (성수기)</td>
                          <td>90% 환불 (송금수수료 포함)</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <a className="btn btn-qubico" href="/reservation/reserve">
                  <i className="fa fa-shopping-cart"></i>예약하기
                </a>
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  </Layout>
)

export default Container
